import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import axios from 'axios'
import Qs from 'qs'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const a = axios.create({
  baseURL: 'https://wenju2.mdservice.cn/client',
  responseType: 'json',
  transformRequest(data) {
    return Qs.stringify(data)
  },
})

a.interceptors.response.use(r => r, (error) => {
  if (error.response) {
    const resp = error.response
    console.log(resp)
    if (resp.request.responseURL.match(/client\/order\/list/)) {
      return Promise.reject(error)
    }
  }
  if (!localStorage.getItem('errorAlertFlag')) {
    alert('账号信息过期，请重新登录')
    localStorage.setItem('errorAlertFlag', 1)
  }
  router.push('/login')
  return
})

router.beforeEach((to, _, next) => {
  if (!store.getters.isLogin && to.path != "/login") {
    next("/login")
  } else {
    next()
  }
})

const app = createApp(App)
app.config.globalProperties.$staticUrl = 'https://wenju2.mdservice.cn/static/'
app.config.globalProperties.$a = a
app.use(store).use(router).mount('#app')
