<template>
  <div class="container">
      <img src="../assets/logo.png" alt="Logo" class="img-fluid">
      <h1>购物车</h1>
      <ul class="list-group m-2">
        <li v-for="item in $store.state.cart" :key="item.id" class="list-group-item">
          <div class="row">
            <div class="col-2">
              <img :alt="item.name" :src="$staticUrl + item.imageUrl" class="img-fluid">
            </div>
            <div class="col clearfix">
              <span class="d-block">{{ item.name }}</span>
              <span class="goods-unit-price text-nowrap">不含税单价：<span class="text-danger fs-6">{{ formatPrice(item.unitPrice) }}￥</span>/{{ item.description }}</span>
              <div class="float-end">
                <div class="input-group">
                  <button type="button" class="btn btn-sm btn-outline-primary bi bi-dash" @click="updateItem(item, item.cartCount - 1)"></button>
                  <input type="text" class="form-control goods-quantity" :value="item.cartCount" readonly>
                  <button type="button" class="btn btn-sm btn-outline-primary bi bi-plus" @click="updateItem(item, item.cartCount + 1)"></button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="m-1">
        <div class="m-1">
          <label for="contactPerson">经理姓名</label>
          <input type="text" id="contactPerson" class="form-control" v-model="contactPerson">
        </div>
        <div class="m-1">
          <label for="contactPhone">联系电话</label>
          <input type="text" id="contactPhone" class="form-control" v-model="contactPhone">
        </div>
        <div class="m-1">
          <label for="verifyCode">验证码</label>
          <div class="input-group">
            <input type="text" id="verifyCode" class="form-control" v-model="verifyCode" :disabled="!verifyCodeSent">
            <button class="btn btn-outline-primary" :disabled="countDown > 0" @click="sendVerifyCode">{{ countDown > 0 ? countDown + "s" : "获取验证码" }}</button>
          </div>
        </div>
        <div class="m-1">
          <label for="note">备注信息</label>
          <textarea id="note" class="form-control" v-model="note"></textarea>
        </div>
      </div>
      <div class="p-0 rounded-pill bg-info text-end">
        <span class="p-3">
          <span class="text-danger">{{ $store.getters.cartSummary.quantity }}</span>件商品，总计<span class="text-danger">￥{{ $store.getters.cartSummary.price }}</span>
        </span>
        <button role="button" class="btn btn-light btn-outline-primary rounded-pill" @click="submit">提交订单</button>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Cart',
  data() {
    return {
      contactPerson: '',
      contactPhone: '',
      verifyCode: '',
      note: '',
      verifyCodeSent: false,
      countDown: 0
    }
  },
  methods: {
    formatPrice(val) {
      return Number(val).toFixed(3)
    },
    updateItem(item, val) {
      val = Math.max(val, 0)
      if (item.quantityLimit > 0) {
        val = Math.min(val, item.quantityLimit)
      }

      let newItem = {}
      Object.assign(newItem, item, { cartCount: val })
      this.$store.commit('updateCart', {
        item: newItem
      })
    },
    sendVerifyCode() {
      if (!this.contactPhone.match(/1\d{10}/)) {
        alert("请输入正确的手机号码")
        return
      }
      const token = this.$store.getters.token
      const phoneNumber = this.contactPhone
      this.$a.post('/sms/645174', { token, phoneNumber }).then(r => {
        if (r.data.success) {
          this.countDown = 60
          this.verifyCodeSent = true
          let handler = setInterval(() => {
            this.countDown--
            if (this.countDown <= 0) {
              clearInterval(handler)
            }
          }, 1000)
        } else {
          alert("系统错误")
        }
      })
    },
    submit() {
      if (this.$store.state.cart.length == 0) {
        alert("请加入至少一件商品")
        return
      }
      if (this.contactPerson == "") {
        alert("请输入经理姓名")
        return
      }
      if (!this.contactPhone.match(/1\d{10}/)) {
        alert("请输入正确的手机号码")
        return
      }
      if (this.verifyCode == "") {
        alert("请输入验证码")
        return
      }
      let itemList = []
      for (let i of this.$store.state.cart) {
        itemList.push({ id: i.id, quantity: i.cartCount })
      }

      const token = this.$store.getters.token
      const clientName = this.contactPerson
      const note = this.note
      const verifyCode = this.verifyCode
      const items = JSON.stringify(itemList)
      this.$a.post('/order/add', { token, clientName, note, verifyCode, items })
        .then(r => {
          if (!r.data.success) {
            alert("系统错误")
            return
          }
          alert("提交成功")
          this.$store.commit("clearCart")
          this.$router.push("/")
        })
    }
  },
}
</script>
<style scoped>
.goods-unit-price {
  font-size: 12px;
}
.goods-quantity {
  text-align: center;
  max-width: 2rem;
  padding: 0rem .1rem;
}
</style>