<template>
  <div v-if="client != null">
    <span class="bi bi-person-circle">{{ client.name }}</span>
    <router-link to="/order" class="btn btn-link">订单查询</router-link>
    <button type="button" class="btn btn-link" @click="logout">退出</button>
  </div>
</template>
<script>
export default {
  name: 'ClientInfo',
  computed: {
    client() {
      return this.$store.state.client
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  },
}
</script>
