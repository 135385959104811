 <template>
  <div class="container-xl">
    <div class="mt-3 row">
        <div class="col px-1">
          <img src="../assets/logo.png" alt="Logo" class="w-100 mb-1">
          <div class="input-group">
            <input type="text" class="form-control" v-model="searchText">
            <button type="button" class="btn btn-outline-primary">搜索</button>
          </div>
          <client-info />
        </div>
        <div class="col px-1">
          <client-service />
        </div>
        <div class="col px-1">
          <banner-bar />
        </div>
    </div>
    <div class="row">
      <div class="col-1">
        <div class="list-group" v-for="(gg, i) in goodsGroups" :key="gg.id">
          <button
            type="button"
            class="list-group-item list-group-item-action text-center gg-text" 
            :class="{active: i == goodsGroupIndex && searchText.length==0}"
            @click="changeGoodsGroup(i)">
            <img :src="$staticUrl + gg.imageUrl" class="gg-icon"><br>
            {{ gg.name }}
          </button>
        </div>
      </div>
      <div class="col mb-5">
        <div class="d-flex flex-wrap">
          <goods-block v-for="goods of goodsList" :key="goods.id" :goods="goods"/>
        </div>
      </div>
    </div>
  </div>
  <shop-cart />
  <notice-dialog />
</template>

<script>
// @ is an alias to /src
import NoticeDialog from '@/components/NoticeDialog.vue'
import GoodsBlock from '@/components/GoodsBlock.vue'
import ClientInfo from '@/components/ClientInfo.vue'
import ShopCart from '@/components/ShopCart.vue'
import BannerBar from '@/components/BannerBar.vue'
import ClientService from '@/components/ClientService.vue'

export default {
  name: 'Home',
  components: {
    NoticeDialog,
    GoodsBlock,
    ClientInfo,
    ShopCart,
    ClientService,
    BannerBar
  },
  data() {
    return {
      searchText: '',
      goodsGroupIndex: 0,
      goodsGroups: [],
      goodsList: [],
    }
  },
  methods: {
    changeGoodsGroup(val) {
      const token = this.$store.getters.token
      this.goodsGroupIndex = val
      this.searchText = ''
      this.$a.get('/group/' + this.goodsGroups[val].id, { params: { token, page: 0, size: 1000 } })
        .then(r => {
          this.goodsList = r.data.content
        })
    },
  },
  watch: {
    searchText(val) {
      const token = this.$store.getters.token
      if (val.length > 0) {
        this.$a.get('/group/search', { params: { token, keyword: val, page: 0, size: 1000 } })
          .then(r => {
            this.goodsList = r.data.content
          })
      } else {
        this.changeGoodsGroup(this.goodsGroupIndex)
      }
    },
  },
  created() {
    const token = this.$store.getters.token
    this.$a.get('/group/list', { params: {token} }).then(r => {
      this.goodsGroups = r.data
      this.changeGoodsGroup(0)
    })
  },
}
</script>

<style scoped>
.gg-icon {
  width: 2rem;
}
.gg-text {
  font-size: 12px;
}
</style>
