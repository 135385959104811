<template>
  <div>
    <p class="kf mb-0">
      以下有更多方式与我们联系：<br>
      服务热线：4007168508转268（文具部）<br>
      文具热线：020-66221195<br>
      邮箱地址：wenju@mdservice.com.cn<br>
      以下扫一扫即可添加客服微信哦！
    </p>
    <div class="row m-0">
      <div class="col px-1">
        <img src="../assets/kf1.png" alt="KF1" class="img-fluid">
      </div>
      <div class="col px-1">
        <img src="../assets/kf2.png" alt="KF2" class="img-fluid">
      </div>
    </div>
  </div>
</template>
<style scoped>
.kf {
  font-size: 12px;
  line-height: 1.3;
}
</style>
