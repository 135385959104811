import { createStore } from 'vuex'

export default createStore({
  strict: true,
  state: function() {
    let cart = []
    let client = {}

    const localClient = localStorage.getItem("client")
    if (localClient !== null) {
      client = JSON.parse(localClient)
    }

    const localCart = localStorage.getItem("cart")
    if (localCart !== null) {
      cart = JSON.parse(localCart)
    }

    return { client, cart }
  },
  getters: {
    isLogin(state) {
      if (state.client == null) {
        return false
      }
      return state.client.token
    },
    token(state) {
      if (state.client == null) {
        return null
      }
      return state.client.token
    },
    cartSummary(state) {
      let price = 0
      let quantity = 0
      for (let item of state.cart) {
        price += item.unitPrice * item.cartCount
        quantity += item.cartCount
      }
      price = Number(price).toFixed(3)
      return { price, quantity }
    },
  },
  mutations: {
    login(state, payload) {
      state.client = payload.client
      localStorage.setItem('client', JSON.stringify(state.client))
      localStorage.removeItem('errorAlertFlag')
    },
    logout(state) {
      state.client = {}
      state.cart = []
      localStorage.clear()
    },
    updateCart(state, payload) {
      const cartItem = payload.item
      const idx = state.cart.findIndex(v => v.id == cartItem.id)
      if (cartItem.cartCount == 0) {
        if (idx != -1) {
          state.cart.splice(idx, 1)
        }
      } else {
        if (idx != -1) {
          state.cart[idx].cartCount = cartItem.cartCount
        } else {
          state.cart.push(cartItem)
        }
      }
      localStorage.setItem("cart", JSON.stringify(state.cart))
    },
    clearCart(state) {
      state.cart = []
      localStorage.setItem("cart", "[]")
    }
  },
  actions: {
  },
  modules: {
  }
})
