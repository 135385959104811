<template>
  <div class="position-fixed w-75 start-50 bottom-0 mb-2 translate-middle-x shadow rounded-pill bg-info text-end cart">
    <span class="p-3">
      <span class="text-danger">{{ $store.getters.cartSummary.quantity }}</span>件商品，总计<span class="text-danger">￥{{ $store.getters.cartSummary.price }}</span>
    </span>
    <router-link to="/cart" role="button" class="btn btn-light btn-outline-primary rounded-pill">购物车</router-link>
  </div>
</template>
<style scoped>
.cart {
  z-index: 99;
}
</style>
