<template>
  <div class="container login-form">
    <img src="../assets/logo.png" class="img-fluid" alt="Logo">
    <h1>客户登录</h1>
    <div class="row my-3">
      <label for="username" class="col-form-label col-md-2">用户名</label>
      <div class="col-md-10">
        <input type="text" id="username" class="form-control" v-model="username">
      </div>
    </div>
    <div class="row my-3">
      <label for="password" class="col-form-label col-md-2">密码</label>
      <div class="col-md-10">
        <input type="password" id="password" class="form-control" v-model="password">
      </div>
    </div>
    <button type="button" class="btn btn-primary" @click="login">登录</button>
    <div class="fixed-bottom text-center pb-3">
    <a href="https://beian.miit.gov.cn/#/Integrated/index">戴伟斯电子科技有限公司粤ICP备17077896号-1 粤ICP备17077896号-2 粤ICP备17077896号-4 粤ICP备17077896号-5</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    login() {
      this.$a.post('/login', {
        username: this.username,
        password: this.password,
      }).then(r => {
        if (r.data.success != true) {
          alert("密码错误")
          return;
        }
        let client = r.data.client
        this.$store.commit('login', { client })
        this.$router.push('/')
      })
    },
  }
}
</script>

<style scoped>
.login-form {
  max-width: 50rem;
}
</style>
