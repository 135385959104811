<template>
  <div class="goods border m-1 px-1 pb-1">
    <div class="text-center">
      <img :src="$staticUrl + goods.detailImageUrl" :alt="goods.name">
    </div>
    <p class="goods-name overflow-hidden pb-1">{{ goods.name }}</p>
    <div class="clearfix">
      <span class="goods-unit-price text-nowrap">{{ priceDescription }}：<span class="text-danger fs-6">{{ formatedPrice }}￥</span>/{{ goods.description }}</span>
      <div class="float-end">
        <div class="input-group">
          <button type="button" class="btn btn-sm btn-outline-primary bi bi-dash" @click="count--"></button>
          <input type="text" class="form-control text-center goods-quantity" v-model="count">
          <button type="button" class="btn btn-sm btn-outline-primary bi bi-plus" @click="count++"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GoodsBlock',
  props: [
    'goods',
  ],
  data() {
    for (let cartItem of this.$store.state.cart) {
      if (cartItem.id == this.goods.id) {
        return { count: cartItem.cartCount }
      }
    }
    return { count: 0 }
  },
  computed: {
    formatedPrice() {
      return Number(this.goods.unitPrice).toFixed(3)
    },
    priceDescription() {
      if (this.$store.state.client.priceIncludeTax) {
        return '含税单价'
      } else {
        return '不含税单价'
      }
    }
  },
  watch: {
    count: function (val) {
      val = Math.max(val, 0)
      if (this.goods.quantityLimit > 0) {
        val = Math.min(this.goods.quantityLimit, val)
      }
      this.count = val
      
      let item = {}
      Object.assign(item, this.goods, { cartCount: val })
      this.$store.commit('updateCart', { item })
    }
  },
}
</script>
<style scoped>
.goods {
  width: 14.3rem;
}

.goods img {
  width: 12rem;
}

.goods-name {
  height: 3.5rem;
  font-size: 12px;
}

.goods-unit-price {
  font-size: 12px;
}

.goods-quantity {
  max-width: 2rem;
  padding: 0rem .1rem;
}
</style>
