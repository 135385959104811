<template>
   <div v-if="enabled" ref="noticeModal" class="modal fade">
      <div class="modal-dialog">
         <div class="modal-content">
            <img alt="Notice" :src="imageUrl" class="img-fluid" />
         </div>
      </div>
   </div>
</template>
<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'
export default {
   name: 'NoticeDialog',
   data() {
      return {
         enabled: false,
         imageUrl: '',
      }
   },
   created() {
      const token = this.$store.getters.token
      this.$a.get('/notice', { params: { token } }).then(r => {
         this.enabled = r.data.enabled
         this.imageUrl = this.$staticUrl + r.data.imageUrl
         this.$nextTick(() => {
            if (this.enabled) {
               const modal = new bootstrap.Modal(this.$refs.noticeModal)
               modal.show()
            }
         })
      })
   },
}
</script>
