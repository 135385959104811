<template>
  <div id="bannerBar" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#bannerBar"
        v-for="(b, i) in banners" :key="b.id" 
        :data-bs-slide-to="i"
        :class="{ active: i == 0 }"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item" v-for="(b, i) in banners" :key="b.id" :class="{ active: i == 0}">
        <img :src="$staticUrl + b.imageUrl" class="d-block w-100" :alt="b.name" @click="click(b)">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#bannerBar" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">上一个</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#bannerBar" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">下一个</span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'BannerBar',
  data() {
    return { banners: [] }
  },
  created() {
    this.$a.get('/banners', { params: { token: this.$store.getters.token } }).then(r => {
      this.banners = r.data
    })
  },
  methods: {
    click(banner) {
      if (banner.goods != null) {
        let item = {}
        Object.assign(item, banner.goods, { cartCount: 1 })
        for (let i of this.$store.state.cart) {
          if (i.id == item.id) {
            item.cartCount += i.cartCount
            break
          }
        }
        this.$store.commit('updateCart', { item })
        alert("已加入购物车")
      }
    }
  },
}
</script>
