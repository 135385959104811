<template>
  <div class="container">
    <img src="../assets/logo.png" alt="Logo" class="img-fluid">
    <h1>历史订单</h1>
    <div class="row">
      <div class="col-md-1">
        <label for="phone">手机号</label>
      </div>
      <div class="col-md-3">
        <input type="text" id="phone" class="form-control" v-model="phone">
      </div>
      <div class="col-md-1">
        <label for="verifyCode">验证码</label>
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <input type="text" id="verifyCode" class="form-control" :disabled="!verifyCodeSent" v-model="verifyCode">
          <button class="btn btn-outline-primary" :disabled="countDown > 0" @click="sendVerifyCode">{{ countDown > 0 ? countDown + "s" : "获取验证码" }}</button>
        </div>
      </div>
      <div class="col-md-1">
        <button class="btn btn-outline-primary" @click="query">查询</button>
      </div>
      <div class="col"></div>
    </div>
    <table class="table table-hover table-stripped">
      <thead>
        <tr>
          <th>订单日期</th>
          <th class="w-25">快递信息</th>
          <th>经理姓名</th>
          <th>状态</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o of orders" :key="o.id">
          <td>
            <button class="btn btn-link" @click="showOrder(o)">{{ formatTime(o.time) }}</button>
          </td>
          <td>{{ o.shippingInformation }}</td>
          <td>{{ o.clientName }}</td>
          <td>{{ o.finished?'已完成':'已下单成功' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div ref="orderModal" v-if="order != null" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title h5">订单详情</span>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <table class="table">
            <tr>
              <td>订单日期</td>
              <td>{{ formatTime(order.time) }}</td>
              <td>经理名称</td>
              <td>{{ order.clientName }}</td>
            </tr>
            <tr>
              <td>客户编号</td>
              <td>{{ order.client.username }}</td>
              <td>客户名称</td>
              <td>{{ order.client.name }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <table class="table">
                  <thead>
                    <tr>
                      <th>名称</th>
                      <th class="text-nowrap">数量</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="oi of order.orderItems" :key="oi.id">
                      <td>{{ oi.name }}</td>
                      <td>{{ oi.quantity }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>快递信息</td>
              <td colspan="3">{{ order.shippingInformation }}</td>
            </tr>
            <tr>
              <td>总价</td>
              <td colspan="3">{{ order.totalPrice }}</td>
            </tr>
            <tr>
              <td>状态</td>
              <td colspan="3">{{ order.finished?"已完成":"已下单成功" }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'

export default {
  name: 'HistoryOrder',
  data() {
    return {
      phone: '',
      verifyCode: '',
      countDown: 0,
      verifyCodeSent: false,
      orders: [],
      order: null,
    }
  },
  methods: {
    formatTime(val) {
      return val.substring(0, 10) + " " + val.substring(11, 19)
    },
    sendVerifyCode() {
      if (!this.phone.match(/1\d{10}/)) {
        alert("请输入正确的手机号码")
        return
      }
      const token = this.$store.getters.token
      const phoneNumber = this.phone
      this.$a.post('/sms/661387', { token, phoneNumber }).then(r => {
        if (r.data.success) {
          this.countDown = 60
          this.verifyCodeSent = true
          let handler = setInterval(() => {
            this.countDown--
            if (this.countDown <= 0) {
              clearInterval(handler)
            }
          }, 1000)
        } else {
          alert("系统错误")
        }
      })
    },
    query() {
      if (!this.phone.match(/1\d{10}/)) {
        alert("请输入正确的手机号码")
        return
      }
      if (this.verifyCode == "") {
        alert("请输入验证码")
        return
      }
      const token = this.$store.getters.token
      const verifyCode = this.verifyCode
      this.$a.get('/order/list', { params: { token, verifyCode }}).then(r => {
        this.orders = r.data
      }).catch(() => {
        alert("验证码错误")
      })
    },
    showOrder(o) {
      console.log(o)
      this.order = o
      this.$nextTick(() => {
        const modal = new bootstrap.Modal(this.$refs.orderModal)
        modal.show()
      })
    }
  }
}
</script>
